import { Button, FormControl, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postWishlistItemDetails, getMetadata } from "../../../services/WishlistService";
import { ItemDetails } from "../../../services/ItemDetails";
import { ModalProps } from "./modalProps";
import { useEffect, useState } from "react";
import "./addItemModal.css";

export const AddItemModal = ({ show, onHide, id }: ModalProps<ItemDetails>) => {
  const { register, handleSubmit, reset, setValue} = useForm<ItemDetails>();
  const [metadata, setMetadata] = useState<any>(null);

  useEffect(() => {
    if (show) {
      setMetadata(null);
      reset();  
    }
  }, [show, reset]);

  const onSubmit = (data: ItemDetails) => {
    postWishlistItemDetails(id, data).then((data) => {
      reset();
      onHide();
    });
  };
  
  const handleLinkBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const url = event.target.value;
    if (url) {
      try {
        const data = await getMetadata(url);
        console.log(data);
        setMetadata(data);
        if (data.title){
          setValue('details', data.title);
        }
        if (data.image){
          setValue('image', data.image);
        }
        // Optionally, you can set the metadata to form fields
        // setValue('title', data.title || '');
        // setValue('description', data.description || '');
      } catch (error) {
        console.error('Error fetching link metadata:', error);
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            className="formControl"
            {...register("details")}
            placeholder="Description"
          />
          <FormControl
            className="formControl"
            {...register("link")}
            placeholder="Link to item or store"
            onBlur={handleLinkBlur}
          />
           {metadata && (
            <div className="metadata-preview">
              <p><strong>Title:</strong> {metadata.title}</p>
              <p><strong>Description:</strong> {metadata.description}</p>
              {metadata.image && (
                <div className="image-container">
                  <img src={metadata.image} alt="Preview" className="metadata-image" />
                </div>
              )}
            </div>
          )}
          <Button variant="secondary" className="button" onClick={onHide}>
            Close
          </Button>
          <Button type="submit" variant="primary" className="button">
            Add Item
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
